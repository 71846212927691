<template>
    <div style="background-position: center; background-repeat: no-repeat; background-size: cover;" v-bind:style="{'background-image':png_path,'width':png_width,'height':png_height}">
        <div class="menu" style="width: 80px;position: fixed;z-index: 9999;">
          <select class="form-select" v-model="current_row" @change="set_row">
            <option v-for="row in rows" v-bind:key="row">{{ row }}</option>
          </select>
        </div>
          <FrontView 
            :calculate_results="calculate_results" 
            :row="row"
            :move_violation_to_center="false"
            style="cursor: move;"
          ></FrontView>
    </div>
</template>

<script>
import FrontView from "../index/front-view/FrontView.vue";
import Snap from "snapsvg-cjs";

export default {
  components: {
    FrontView
  },
  data: function () {
    return {
      png_path: "",
      png_width: "0",
      png_height: "0",
      calculate_results: {},
      row: 0,
      current_row: 0,
      rows: [],
    }
  },
  methods: {
    set_row(event){
      this.row = parseInt(event.target.value)
    }
  },
  mounted() {
    let
      move = function(dx,dy) {
        this.attr({transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]});
      },
      start = function() {this.data('origTransform', this.transform().local )}

    fetch(`/api/pdf/${this.$route.params.id}/`)
      .then((response) => response.json())
      .then((results) => {
        this.png_path = `url(${results.png_path})`
        this.png_width = `${results.png_width}px`
        this.png_height = `${results.png_height}px`
        this.calculate_results = results.calculation_results

        this.row = results.row
        this.current_row = this.row
        this.rows = [...Array(this.calculate_results.total_rows).keys()]

        let 
          svg = Snap("#svg-side-view"),  
          gamma = parseFloat(this.calculate_results.input_data_konv.gamma)

        svg.transform(`translate(0, 550) rotate(${gamma}})`)
        svg.drag(move, start)
      });
  },
};
</script>

